<template>
  <div class="ter">
    <!-- <a-button class="editable-add-btn" @click="handleAdd" type="primary">
      添加导师信息
    </a-button> -->
    <!-- <p styel='text-align: left;font-size: 16px;'>
      添加成员（复制连接发送给成员）：{{ joinurl }}
      <a-icon
        type="copy"
        @click="_copy(joinurl)"
        theme="twoTone"
        two-tone-color="#52c41a"
      />
    </p> -->
    <el-table
      :data="dataSource"
      style="width: 100%"
      max-height="500"
      border
      stripe
      :resizable="false"
    >
      <!-- <el-table-column  prop="project" label="参赛项目" >
        </el-table-column> -->
        
      
      <el-table-column fixed prop="name" label="姓名" :resizable="false" v-if='editsort=="true" || editsort ==true' :key="Math.random()">
        <template slot-scope="scope" >
          <div>
            {{ dataSource[scope.$index].name }}
            <p style="float: right;" >
              <i
                class="el-icon-caret-top"
                v-if="scope.$index != 0"
                style="display: block;cursor: pointer;"
                @click="topFun(scope.$index, dataSource[scope.$index].id, 'up')"
              ></i>
              <i
                class="el-icon-caret-bottom"
                style="cursor: pointer;"
                v-if="scope.$index != dataSource.length - 1"
                @click="topFun(scope.$index, dataSource[scope.$index].id, 'down')"
              ></i>
            </p>
          </div>
        </template>
      </el-table-column>
        <el-table-column fixed prop="name" label="姓名" :resizable="false"   v-else ></el-table-column>
      <el-table-column prop="role" label="身份" :resizable="false">
      </el-table-column>
      <!-- <el-table-column label="排序"  :resizable='false'>
          <template slot-scope="scope">
            <el-input-number v-model="scope.$index" size="mini" controls-position="right" @change="pxFun(scope.$index,dataSource[scope.$index].name)" :min="1" :max="100"></el-input-number>

          </template>
        </el-table-column> -->
      <el-table-column label="操作" width="120" :resizable="false">
        <template slot-scope="scope">
          <a-popconfirm
            v-if="editmem && dataSource[scope.$index].role != '队长'"
            :title="`确定删除成员${dataSource[scope.$index].name}？`"
            cancelText="取消"
            okText="确定"
            @confirm="() => onDelete(scope.$index, dataSource[scope.$index].id)"
          >
            <a href="javascript:;">删除</a>
          </a-popconfirm>

          <span v-else>暂无</span>
        </template>
      </el-table-column>
    </el-table>
    <a-button
      type="primary"
      @click="dialogVisible = true"
      style="margin-top: 15px"
      :disabled="!editmem"
      >添加成员</a-button
    >
    <div style="color: red" v-if="curType == '编译系统大赛'|| curType == '小米大赛'|| curType == '曙光大赛'">
      注意： 【{{ curType }} -- 队长+队员一共最多4名 】【 参赛成员的顺序将作为证书姓名顺序使用 】
    </div>
    <div style="color: red" v-else>
      注意：【 {{ curType }} -- 队长+队员一共最多3名 】【 参赛成员的顺序将作为证书姓名顺序使用 】
    </div>
  
    <el-dialog
      title="还没有邀请队友吗？赶快复制邀请链接发送给您的队友邀请Ta加入您的队伍吧！
"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div>成员登录或注册后即可自动加入队伍</div>
      <span
        >{{ joinurl }}
        <a-icon
          type="copy"
          @click="_copy(joinurl)"
          theme="twoTone"
          two-tone-color="#52c41a"
      /></span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false" size="mini"
          >关闭</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
// import EditableCell from "@/components/EditableCell.vue";
export default {
  // components: {
  //   EditableCell,
  // },
  data() {
    return {
      editsort:false,

      scroll: { x: 800, y: 425 },
      teamid: "",
      id: "",
      dis: true,
      editmem: false,
      joinurl: "",
      info: null,
      type: "本期",

      count: 0,
      dataSource: [],
      dataSourceAry: [],
      dialogVisible: false,
      curType: "",
      // edit:true
    };
  },
  // computed: {
  //   fullName() {
  //     return `${this.firstName} ${this.lastName}`;
  //   },
   
  // },
  mounted() {
    let url = window.location.href;
    if (
      window.localStorage.getItem("anjing-info") &&
      window.localStorage.getItem("anjing-info") != "" &&
      url.indexOf("enrollDetail") != -1
    ) {
      this.info = JSON.parse(window.localStorage.getItem("anjing-info"));
      console.log(this.info)
      this.editmem = this.info.edit;
      this.editsort =  window.localStorage.getItem('anjing-parent-type-editsort');
      console.log(this.editsort)
      this.dataSource = this.info.member;
      for (let i = 0; i < this.dataSource.length; i++) {
        this.dataSourceAry.push(this.dataSource[i].id);
      }
      this.joinurl = this.info.joincode;
      this.type = this.info.type;
      this.id = this.info._id;
      this.teamid = this.info.teamid;
      this.curType = this.info.parent;
      console.log('this.curTypethis.curType',this.curType)
      console.log(this.info);
      // this.edit = this.info.edit
    }
   this.init()
   
  },
  props: {
    tguo: Boolean,
  },
//   watch:{
//     curType(o,n){
// console.log(n,o)
// this.curType = n;
// console.log('watch',this.curType)
//     }
//   },
  methods: {
    load(){
      return Math.randow()
    },
    topFun(index, id, type) {
      debugger
      if (type == "down") {
        // 向下
        // let temp = this.dataSourceAry[index + 1];
        console.log(this.dataSourceAry)
        this.dataSourceAry[index] = this.dataSourceAry[index + 1];
        console.log(this.dataSourceAry)
        this.dataSourceAry[index + 1] = id;
        console.log(this.dataSourceAry)
      } else {
        // 向上
        console.log(this.dataSourceAry)
        this.dataSourceAry[index] = this.dataSourceAry[index - 1];
        console.log(this.dataSourceAry)
        this.dataSourceAry[index - 1] = id;
        console.log(this.dataSourceAry)
      }

      let data = {
        op: "setsort",
        _id: window.localStorage.getItem("anjing-parent-type-_id"),
         member:id, //this.dataSourceAry,
        sort:type

      };
      let _this = this;
      this.$ajax
        .post(this.url + "/signup.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.init();
          } else {
            _this.$message.error(response.data.msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    _copy(context) {
      // 创建输入框元素
      let oInput = document.createElement("input");
      // 将想要复制的值
      oInput.value = context;
      // 页面底部追加输入框
      document.body.appendChild(oInput);
      // 选中输入框
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand("Copy");
      // 弹出复制成功信息
      this.$message.success("复制成功");
      // 复制后移除输入框
      oInput.remove();
    },
    go() {
      this.$emit("btnFun", 3);
    },
    onCellChange(key, dataIndex, value) {
      const dataSource = [...this.dataSource];
      const target = dataSource.find((item) => item.key === key);
      if (target) {
        target[dataIndex] = value;
        this.dataSource = dataSource;
        this.dataSourceAry = [];
        for (let i = 0; i < this.dataSource.length; i++) {
          this.dataSourceAry.push(this.dataSource[i].id);
        }
        this.dis = false;
      }
    },
    init() {

      let data = {
        op: "getteaminfo",
        // type:type,
        _id: window.localStorage.getItem("anjing-parent-type-_id"),
      };

      let _this = this;
      this.$ajax
        .post(this.url + "/signup.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.dataSource = response.data.data.member;
            _this.dataSourceAry=[];
            _this.curType = response.data.data.parent;
            console.log('_this.curTypethis.curType',_this.curType)
            for (let i = 0; i < _this.dataSource.length; i++) {
            _this.dataSourceAry.push(_this.dataSource[i].id);
          }
          
            _this.editsort = response.data.data.editsort;
            window.localStorage.setItem('anjing-parent-type-editsort',response.data.data.editsort)
   
      //       _this.info = response.data.data;
      // console.log(_this.info)
      // _this.editmem = _this.info.edit;
      // _this.editsort =  window.localStorage.getItem('anjing-parent-type-editsort');
      // console.log(_this.editsort)
      // _this.dataSource = _this.info.member;
      // for (let i = 0; i < _this.dataSource.length; i++) {
      //   _this.dataSourceAry.push(_this.dataSource[i].id);
      // }
      // _this.joinurl = _this.info.joincode;
      // _this.type = _this.info.type;
      // _this.id = _this.info._id;
      // _this.teamid = _this.info.teamid;

          } else {
            _this.$message.info(response.data.msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    onDelete(key, id) {
      // if(this.edit){
      //   this.$message.error('您没有权限操作！');
      //   return;
      // }
      let data = {
        op: "delmember",
        // teamid: this.teamid,
        _id: this.id,
        memberid: id,
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/signup.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            const dataSource = [..._this.dataSource];
            _this.dataSource = dataSource.filter((item) => item.id !== id);
            if (_this.dataSource.length == 0) {
              _this.dis = true;
            }
            _this.$message.success(response.data.msg);
          } else {
            _this.$message.error(response.data.msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    handleAdd() {
      const { count, dataSource } = this;
      const newData = {
        key: count,
        name: "",
        phone: "",
        email: "",
      };
      this.dataSource = [...dataSource, newData];
      this.dataSourceAry = [];
      for (let i = 0; i < this.dataSource.length; i++) {
        this.dataSourceAry.push(this.dataSource[i].id);
      }
      this.count = count + 1;
      this.dis = false;
    },
    saveFun() {
      let url = window.location.href;
      let id = "";
      if (
        window.localStorage.getItem("anjing-info") &&
        window.localStorage.getItem("anjing-info") != "" &&
        url.indexOf("enrollDetail") != -1
      ) {
        id = this.id;
      } else {
        id = window.localStorage.getItem("anjing_id");
      }
      let data = {
        op: "edittutor",
        _id: id,
        list: this.dataSource,
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/signup.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.$emit("btnFun", 3);
          } else {
            _this.$message.error(response.data.msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    edit(val) {
      if (val) {
        this.dis = true;
      }
    },
  },
};
</script>
<style>
.ter {
  margin-top: 20px;
}
.editable-cell {
  position: relative;
}

.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell-icon,
.editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}

.editable-cell-icon {
  line-height: 18px;
  display: none;
}

.editable-cell-icon-check {
  line-height: 28px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}
</style>