<template>
  <div>
    <heade currentrStr="enroll"></heade>
     <a-breadcrumb class='common-width'>
      <a-breadcrumb-item><a  href="javascript:;" @click="to('./enroll')">上一页</a></a-breadcrumb-item>
      <a-breadcrumb-item
        ><a href="">报名信息详情</a></a-breadcrumb-item
      >
    </a-breadcrumb>
    <div class="enroll common-width">
          <!-- <a slot="extra" href="javascript:;" @click="to('./enroll')" style='margin-top: 20px;float: right;'>返回上一页</a> -->
      <a-tabs default-active-key="0" >
        <a-tab-pane key="0" tab="参赛说明"> 
          <div style="    padding: 20px 0 0 10%;    text-align: left;">
            <VueMarkdown :source="content"  class="articalContent markdown-body"></VueMarkdown></div>
       
        </a-tab-pane>
        <a-tab-pane key="1" tab="参赛队伍信息"> 
              <signCreat class='addPad'> </signCreat>   
        </a-tab-pane>
        <a-tab-pane key="2" tab="参赛成员" v-if="longxin"> 
               <member  class='addPad'> </member>  
        </a-tab-pane>
        <a-tab-pane key="3" tab="参赛导师"> 
                 <terCreat :tguo='false' class='addPad'> </terCreat>  
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>
<script>
import heade from "@/components/header.vue";
import signCreat from "@/components/signCreat.vue";
import terCreat from "@/components/terCreat.vue";
import member from "@/components/member.vue";
import VueMarkdown from 'vue-markdown'
export default {
  data() {
    return {
      screenWidthD:null,
      content:JSON.parse( window.localStorage.getItem("anjing-info")).content,
      longxin:true
    };
  },
  components: {
    heade,
    signCreat,
    terCreat,
    member,
    VueMarkdown
  },
  mounted(){
    // this.content =JSON.parse( window.localStorage.getItem("anjing-info")).content;
      if((JSON.parse( window.localStorage.getItem("anjing-info")).project).indexOf('龙芯')!=-1 && window.localStorage.getItem('anjing-teamtype')=='个人赛'){//是龙芯，成员隐藏
      this.longxin = false;
      }
   
  },
  methods: {

    callback(key) {
      console.log(key);
    },
    to(url) {
      this.$router.push({ path: url });
    },
    
    
  },
};
</script>
<style scoped>
body {
  /* background: #ccc; */
}
.enroll {
/* width: 1240px;
  margin: 0 auto; */
  /* display: flex;
    justify-content: space-between; */

  /* padding-right: 20px; */
}
/deep/ .ant-card-body {
  text-align: left;
}
/deep/ .ant-card-bordered {
  display: inline-block;
  width: 100% !important;
  margin-right: 10px;
  margin-top: 50px;
}
/deep/ .ant-tabs .ant-tabs-left-bar {
  padding-top: 50px;
}
/deep/ .ant-tabs .ant-tabs-left-content {
  /* height: calc(100vh - 50px); */
  overflow: auto;
}
/deep/.ant-table-wrapper{
      border: 1px solid #ededed;
}
/deep/ .ant-breadcrumb {

  margin: 30px auto !important;
  padding: 10px;
  text-align: left;
}
/deep/ .ant-form-item{
  margin-bottom:10px;
}
.addPad{
      padding:0 10px;
}
@media (max-width: 1000px) {
.ant-breadcrumb{
    margin: 10px auto !important;
  
  }
}

</style>
